import React, { useEffect, useState } from 'react';
import HeaderNav from '../../Templates/HeaderNav';
import Collapsible from '../../Templates/CollapaseList';
import { getServiceCategories } from '../../../Service';
import moment from 'moment';
import AddServiceCategory from '../../Templates/AddServiceCategory';
import { CategoryType } from "../../DropdownOption";
import EditServiceCategory from '../../Templates/EditServiceCategory';

function Service_Categories() {
    const tableHeaders = ["Category", "Sub Category", "Created On"];
    const [data, setData] = useState([]);
    const [dropdownVisibility, setDropdownVisibility] = useState(
        Array(data.length).fill(false) // Initialize with 'false' for each row
    );
    const [addCategory, setaddCategory] = useState(false);  
    const [editCategory, seteditCategory] = useState(false);
    const [categoryData, setCategoryData] = useState([]);  
    // const categories = ["Sports", "Primary School", "Play Zone", "Events", "Coaching", "Academic Classes"];

    const fetchServiceCategoryList = async () => {
        try {
            const result = await getServiceCategories("/getServicesCategories");
            console.log("Fetched Data: ", result);
            setData(result);
        } catch (error) {
            console.error("Failed to fetch categories:", error);
        }
    };

    useEffect(() => {
        fetchServiceCategoryList();
    }, []);

    // Toggle the dropdown visibility for a specific row
    const toggleDropdown = (index) => {
        setDropdownVisibility((prev) => {
            const updatedVisibility = Array(data.length).fill(false);
            updatedVisibility[index] = !prev[index];
            return updatedVisibility;
        });
    };

    const handleAddCategory = () => {
        setaddCategory(true);
    };

    const handleaddCategorySubmit = () => {
        handleaddCategoryCancel();
        fetchServiceCategoryList();
        // fetchUserCount();
    };

    const handleaddCategoryCancel = () => {
        // toast.success("Form cancelled");
        setaddCategory(false);
        seteditCategory(false);
    };

    const handleEdit = (item,index) => {
        // toast.success("Form cancelled");
        seteditCategory(true);
        setCategoryData(item);
        toggleDropdown(index);
    };

    return (
        <section className="p-4 pt-1 w-100 mobileView" style={{ backgroundColor: "#F7F8F6" }}>
            <HeaderNav name={"Service Categories"} />

            <div className='d-flex justify-content-end mb-2'>
                <button className="btn" onClick={handleAddCategory}>Add</button>
            </div>

            <div className="">
                {CategoryType.map((category) => {
                    const filteredData = data.filter(item => item.Category == category.value);
                    // if (filteredData.length === 0) return null; // Hide collapsible if no data

                    return (
                        <div className="mb-4" key={category}>
                            <Collapsible title={category.label}>
                                <div className="rounded-table service-category m-4" style={{ overflowX: "scroll" }}>
                                    <table className="table m-0 p-5">
                                        <thead style={{ height: "3.5em" }}>
                                            <tr>
                                                {tableHeaders.map((item, index) => (
                                                    <th key={index} style={{ fontSize: "0.82em", color: "#64748B", backgroundColor: "#E5F4FF" }}>
                                                        {item}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody className="w-100">
                                            {filteredData.map((item, index) => (
                                                <tr key={index} style={{ fontSize: "0.8em" }} className="w-100 h-100">
                                                    <td>
                                                        <p>{item.Category}</p>
                                                    </td>
                                                    <td>
                                                        <p>{item.Subcategory}</p>
                                                    </td>
                                                    <td className="status_cell pe-4">
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "baseline",
                                                                width: "100%",
                                                                position: "relative",
                                                            }}
                                                        >
                                                            <p>
                                                                {moment(item.CreatedOn).format(
                                                                    "YYYY-MM-DD HH:mm"
                                                                )}
                                                            </p>
                                                            <p style={{ backgroundColor: "transparent !important" }}>
                                                                &nbsp;&nbsp;&nbsp;
                                                                <img
                                                                    src="/Status-menu.svg"
                                                                    alt="Status menu icon"
                                                                    className="dropdown-toggle"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        toggleDropdown(index);
                                                                    }}
                                                                />
                                                            </p>
                                                            {dropdownVisibility[index] && (
                                                                <ul
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "80%",
                                                                        left: "85%",
                                                                        transform: "translate(-50%, -50%)",
                                                                        listStyle: "none",
                                                                        padding: "0.5em",
                                                                        backgroundColor: "#fff",
                                                                        border: "1px solid #ddd",
                                                                        borderRadius: "8px",
                                                                        width: "6em",
                                                                        zIndex: "1000",
                                                                    }}
                                                                >

                                                                    <li
                                                                        style={{ cursor: "pointer" }}
                                                                      onClick={() => handleEdit(item, index)}
                                                                    >
                                                                        Edit
                                                                    </li>
                                                                </ul>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Collapsible>
                        </div>
                    );
                })}
            </div>
            {addCategory && (
                <AddServiceCategory onClose={handleaddCategoryCancel} onSubmit={handleaddCategorySubmit} />
            )}
            {editCategory && (
                <EditServiceCategory onClose={handleaddCategoryCancel} onSubmit={handleaddCategorySubmit} categoryData={categoryData} />
            )}
        </section>
    );
}

export default Service_Categories;
