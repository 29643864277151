import React from "react";

const Amenities = ({ data }) => {
  return (
    <div className="user_detail_info amenities d-flex overflow-auto">
      <div className="mb-3">
        <div className="amenities-section">
          <p className="mb-2">
            <b>Toilet</b>
          </p>
          <div className="amenities-grid">
            <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="gents-toilet-checkbox"
                checked={data?.Gents_Toilet === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="gents-toilet-checkbox">Gents Toilet</label>
            </div>

            <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="ladies-toilet-checkbox"
                checked={data?.Ladies_Toilet === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="ladies-toilet-checkbox">Ladies Toilet</label>
            </div>

            <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="neutral-toilet-checkbox"
                checked={data?.Gender_Neutral === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="neutral-toilet-checkbox">
                Gender Neutral Toilet
              </label>
            </div>
          </div>
        </div>

        <div className="amenities-section">
          <p className="mb-2">
            <b>Parking</b>
          </p>
          <div className="amenities-grid">
            <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="paid-parking-checkbox"
                checked={data?.Paid_Parking === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="paid-parking-checkbox">Paid Parking</label>
            </div>

            <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="free-parking-checkbox"
                checked={data?.Free_Parking === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="free-parking-checkbox">Free Parking</label>
            </div>

            <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="two-wheeler-parking-checkbox"
                checked={data?.Two_Wheeler_Parking === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="two-wheeler-parking-checkbox">
                Two Wheeler Parking
              </label>
            </div>

            <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="four-wheeler-parking-checkbox"
                checked={data?.Four_Wheeler_Parking === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="two-wheeler-parking-checkbox">
                Four Wheeler Parking
              </label>
            </div>

            <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="wifi"
                checked={data?.WiFi === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="wifi">Wifi</label>
            </div>

            <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="food_facility"
                checked={data?.Cafe_Facility === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="food_facility">Food/Cafe Facilities</label>
            </div>

            <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="women owner"
                checked={data?.Women_Owner === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="women owner">Women Owner Identification</label>
            </div>
          </div>
        </div>

        <div className="amenities-section">
          <p className="mb-2">
            <b>Additional Offerings</b>
          </p>
          <p>{data?.Additional_Offerings || "N/A"}</p>
          {/* <div className="additional_offering">
            <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="birthday-party-checkbox"
                checked={data?.Birthday_Party === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="birthday-party-checkbox">Birthday Party</label>
            </div>

            <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="get-together-checkbox"
                checked={data?.Get_Together === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="get-together-checkbox">Get Together</label>
            </div>

            <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="tournament-checkbox"
                checked={data?.Tournament === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="tournament-checkbox">Tournament</label>
            </div>
            <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="party_checkbox"
                checked={data?.Tournament === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="party_checkbox">Children Party</label>
            </div>

            <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="city-match-checkbox"
                checked={data?.Tournament === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="city-match-checkbox">City Matches</label>
            </div>

            <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="business-checkbox"
                checked={data?.Tournament === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="business-checkbox" style={{ fontSize: "12px" }}>
                Business Events Like Flea Market and Other
              </label>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Amenities;
