import React, { useState } from "react";
import { CategoryType } from "../DropdownOption";
import { toast } from "react-toastify";
import { CreateServiceCategory } from "../../Service";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";

const AddServiceCategory = ({ onClose, onSubmit }) => {

    // const CategoryType = ["sports", "primarySchoolAndDaycare", "playZone", "events", "coaching", "academicClasses"];

    const [category, setcategory] = useState("");
    const [subCategory, setsubCategory] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleFormSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission

        // Check if all fields are filled
        if (category && subCategory) {

            const data = {
                Category: category,
                SubCategory: subCategory,
            };
            // console.log("Submitted Data: ", data);

            // API Call to CreateUser
            try {
                setLoading(true);
                const response = await CreateServiceCategory("/add_serviceCategory", data);
                // console.log("response: ", response);
                if (response) {
                    setLoading(false);
                    onSubmit();
                    // navigate(0);
                } else {
                    setLoading(false);
                }
                // Handle the response if needed
                // Close the modal after success
            } catch (error) {
                setLoading(false);
                onSubmit();
                console.error("Failed to create category:", error);
                toast.error("Failed to create category.");
            }
        } else {
            toast.error("Please fill all input fields correctly.");
        }
    };

    return (
        <>
            {/* Black Overlay */}
            <div
                className="modal-backdrop show"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Black transparent overlay
                    zIndex: 1000, // Ensure it is behind the modal
                }}
            ></div>

            {/* Modal Dialog */}
            <div
                className="modal show"
                style={{
                    display: "block",
                    zIndex: 1000, // Ensure it is above the overlay
                }}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header mx-0 ms-0">
                            <h5 className="modal-title" style={{ fontSize: "1.3em" }}>
                                Add Category
                            </h5>
                            <button
                                type="button"
                                className="btn-close custom-btn-close"
                                aria-label="Close"
                                onClick={onClose} // Close modal
                            ></button>
                        </div>
                        <div className="modal-body p-0">
                            <form onSubmit={handleFormSubmit}>
                                <div
                                    style={{ textAlign: "left" }}
                                    // onSubmit={handleFormSubmit}
                                    className="Add_User_form mx-auto pb-3"
                                >


                                    <div className="form-group add_user">
                                        <label className="add_user_label" htmlFor="user_type">
                                            Category <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <select
                                            id="user_type"
                                            className="form-select mb-2 "
                                            onChange={(e) => setcategory(e.target.value)}
                                            value={category}
                                            style={{ fontSize: "14px" }}
                                            required
                                        >
                                            <option value={""} disabled style={{ color: "#94A3B8" }}>
                                                Select
                                            </option>
                                            {CategoryType.map(
                                                (item, index) => (
                                                    <option key={index} value={item.value}>
                                                        {item.label}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="add_user_label" htmlFor="phone_number">
                                            Sub Category <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="phone_number"
                                            id="phone_number"
                                            maxLength={50}
                                            className="form-control mb-2"
                                            placeholder="Enter Sub Category"
                                            value={subCategory}
                                            onChange={(e) => setsubCategory(e.target.value)}
                                            required
                                        />
                                    </div>


                                </div>
                                <div className="modal-footer pe-4">
                                    <button
                                        type="button"
                                        className="btn btn-secondary Disable_option_cancel"
                                        onClick={onClose} // Close modal
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn"
                                        style={{ width: "5em" }}
                                    // onClick={handleFormSubmit}
                                    // This will now trigger handleFormSubmit because it's within the form
                                    >
                                        Add
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Loading show={loading} />
        </>
    );
};

export default AddServiceCategory;
