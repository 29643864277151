// import React, { createContext, useContext, useState, useEffect } from "react";
// import { getUserType, logout, UserDataFromToken } from "../Service";
// import { UserType } from "./DropdownOption";
// import { toast } from "react-toastify";

// // Create a context for the user's role
// const RoleContext = createContext();

// // Export a hook to use the role context
// export const useRole = () => useContext(RoleContext);

// // RoleProvider component to provide role state to the app
// export const RoleProvider = ({ children }) => {
//   const [role, setRole] = useState(null);
//   const [lastActivity, setLastActivity] = useState(Date.now());

//   const INACTIVITY_LIMIT = 30 * 60 * 1000; // Set inactivity limit to 5 minutes (in milliseconds)

//   // Fetch role from UserDataFromToken
//   const getRoleForAuth = () => {
//     const storedRole = UserType[getUserType() - 1]?.type;

//     if (storedRole) {
//       setRole(storedRole);
//     }
//   };

//   // Reset the inactivity timer when user interacts with the page
//   const resetActivityTimer = () => {
//     setLastActivity(Date.now());
//   };

//   // Log out the user when inactivity limit is reached
//   const handleInactivity = () => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       const now = Date.now();
//       if (now - lastActivity > INACTIVITY_LIMIT) {
//         toast.warning("You have been logged out due to inactivity.");
//         logout();
//       }
//     }
//   };

//   useEffect(() => {
//     // Fetch role on component mount
//     getRoleForAuth();
//     const token = localStorage.getItem("token");
//     // //console.log("token Data: ", token);

//     // Attach event listeners to track user activity
//     const events = ["mousemove", "keydown", "scroll", "click"];
//     events.forEach((event) =>
//       window.addEventListener(event, resetActivityTimer)
//     );

//     // Set an interval to check for inactivity
//     const interval = setInterval(handleInactivity, 1000); // Check every second

//     // Cleanup on component unmount
//     return () => {
//       events.forEach((event) =>
//         window.removeEventListener(event, resetActivityTimer)
//       );
//       clearInterval(interval);
//     };
//   }, [lastActivity]); // Re-run effect when `lastActivity` changes

//   return (
//     <RoleContext.Provider value={{ role, setRole, getRoleForAuth }}>
//       {children}
//     </RoleContext.Provider>
//   );
// };


import React, { createContext, useContext, useState, useEffect, useRef } from "react";
import { getUserType, logout } from "../Service";
import { UserType } from "./DropdownOption";
import { toast } from "react-toastify";

const RoleContext = createContext();

export const useRole = () => useContext(RoleContext);

export const RoleProvider = ({ children }) => {
  const [role, setRole] = useState(null);
  const lastActivityRef = useRef(Date.now()); // Use useRef instead of useState
  const inactivityTimeoutRef = useRef(null);
  const INACTIVITY_LIMIT = 2 * 60 * 60 * 1000; // 2 hours

  // Fetch role from UserDataFromToken
  const getRoleForAuth = () => {
    const storedRole = UserType[getUserType() - 1]?.type;
    if (storedRole) {
      setRole(storedRole);
    }
  };

  // Reset the inactivity timer
  const resetActivityTimer = (event) => {
    lastActivityRef.current = Date.now();
    //console.log(`Activity detected: ${event.type} at ${new Date().toLocaleTimeString()}`);
    
    if (inactivityTimeoutRef.current) {
      clearTimeout(inactivityTimeoutRef.current);
    }
    startInactivityTimer();
  };
  
  
  // Handle user inactivity
  const handleInactivity = () => {
    const token = localStorage.getItem("token");
    const now = Date.now();
    const inactiveTime = now - lastActivityRef.current;
  
    //console.log(`Checking inactivity at: ${new Date(now).toLocaleTimeString()}`);
    //console.log(`Inactive time: ${inactiveTime / 1000} seconds`);
  
    if (token && inactiveTime > INACTIVITY_LIMIT) {
      console.warn("User inactive for too long. Logging out...");
      toast.warning("You have been logged out due to inactivity.");
      logout();
    }
  };
  

  // Start a new inactivity timer
  const startInactivityTimer = () => {
    //console.log("Starting inactivity timer. You will be logged out if inactive for 5 minutes.");
    inactivityTimeoutRef.current = setTimeout(handleInactivity, INACTIVITY_LIMIT);
  };
  

  useEffect(() => {
    getRoleForAuth();
  
    const token = localStorage.getItem("token");
    if (token) {
      //console.log("User logged in, initializing inactivity timer.");
      startInactivityTimer();
    }
  
    // List of events to detect user activity
    const events = ["mousemove", "mousedown", "keydown", "scroll", "touchstart"];
  
    // Attach event listeners to document
    events.forEach((event) => document.addEventListener(event, resetActivityTimer));
  
    return () => {
      // Cleanup event listeners on unmount
      events.forEach((event) => document.removeEventListener(event, resetActivityTimer));
      if (inactivityTimeoutRef.current) {
        //console.log("Cleaning up inactivity timer.");
        clearTimeout(inactivityTimeoutRef.current);
      }
    };
  }, []);
  
  

  return (
    <RoleContext.Provider value={{ role, setRole, getRoleForAuth }}>
      {children}
    </RoleContext.Provider>
  );
};
